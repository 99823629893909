import { css } from '@emotion/react';
import styled from '@emotion/styled';

export default styled.div`
  --content-height: 0;
  --closed-height: 100px;
  --actual-height: var(--closed-height);
  --bottom-spacing: ${({ bottomSpacing }) => bottomSpacing}px;
  --overlay-color: ${({ theme }) => theme.colors.white};
  --bottom-border-color: var(--overlay-color);
  --read-more-shift: 0px;
  --animation-duration: 500ms;

  position: relative;
  max-height: var(--closed-height);
  overflow: hidden;
  transform: translateZ(0);

  ${({ animateHeight }) =>
    animateHeight &&
    css`
      transition: max-height var(--animation-duration) ease;
    `}

  & > .content {
    padding: 0.1px 0 var(--bottom-spacing); // Prevents margin collapse
  }

  & > .read-more {
    pointer-events: initial;
    position: fixed;
    width: 100%;
    height: var(--closed-height);
    top: calc(
      var(--actual-height) - var(--closed-height) + var(--read-more-shift)
    );
    border-bottom: 1px solid var(--bottom-border-color);
    transition: background 0.3s ease, top var(--animation-duration) ease;
    &::before {
      content: '';
      position: absolute;
      inset: 0;
      background: linear-gradient(0deg, var(--overlay-color), transparent);
      opacity: 1;
      transition: opacity 0.3s ease;
    }
    &::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      height: 9999px;
      margin-top: 1px;
      background: var(--overlay-color);
      z-index: -1;
    }
  }

  &.open {
    ${({ collapseBottomSpacing }) =>
      collapseBottomSpacing
        ? css`
            --actual-height: calc(
              var(--content-height) - var(--bottom-spacing)
            );
            max-height: calc(var(--content-height) - var(--bottom-spacing));
          `
        : css`
            --actual-height: var(--content-height);
            max-height: var(--content-height);
          `}
    & > .read-more {
      &::before {
        opacity: 0;
      }
    }
  }
`;
