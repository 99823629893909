import React, { useEffect, useRef, useState } from 'react';
import StyledReadMoreContainer from './ReadMoreContainer.style';

const ReadMoreContainer = ({
  children,
  button,
  open,
  closedHeight = 100,
  bottomSpacing = 60,
  onResize,
  animateHeight = false,
  className = '',
  ...p
}) => {
  const [enabled, setEnabled] = useState(false);

  const wrapperRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    onResize?.();
  }, [open, onResize]);
  useEffect(() => {
    const handler = () => {
      if (!wrapperRef.current || !contentRef.current) return;
      const contentHeight = contentRef.current.offsetHeight;
      wrapperRef.current.style.setProperty(
        '--content-height',
        `${contentHeight}px`
      );
      setEnabled(contentHeight - bottomSpacing > closedHeight);
    };
    window.addEventListener('resize', handler);
    setInterval(handler, 500);
    handler();
    return () => {
      window.removeEventListener('resize', handler);
      clearInterval(handler);
    };
  }, [wrapperRef.current, contentRef.current, closedHeight, bottomSpacing]);

  return (
    <StyledReadMoreContainer
      {...p}
      animateHeight={animateHeight}
      style={{ '--closed-height': `${closedHeight}px` }}
      className={(!enabled || open ? 'open ' : 'closed ') + className}
      ref={wrapperRef}
      bottomSpacing={bottomSpacing}
      collapseBottomSpacing={!enabled}
    >
      <div ref={contentRef} className="content">
        {children}
      </div>
      {enabled && <div className="read-more">{button}</div>}
    </StyledReadMoreContainer>
  );
};
export default ReadMoreContainer;
